/* istanbul ignore file */
import React, { useEffect, useRef, useState } from "react";
import OktaSignIn from "@okta/okta-signin-widget";
import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";
import "./../../src/styles/signin.scss";
import classes from "./../../src/styles/logo.scss";
import { accountUnlockViaEmail, passwordRecoveryViaEmail, accountUnlockConfirmationEmail } from "./../../src/resource/APIServices";
import { useHistory } from "react-router-dom";
import { envConfig, resetPwd, setPwd } from "./../config";
import { useCookies } from 'react-cookie';
import OELogo from "../images/OE.png";
import OELogo2 from "../images/SPOL.png";
import banner from "../images/banner2.jpg";
import Footer from "../CustomUIs/Footer";
import Modal from "../CustomUIs/Modal";
import { fetchFeatureFlag } from "../Utility/helpers/functions";
import { RELEASE_1_0,RELEASE_1_1} from "../Utility/constants";
import { getSpiningLoader } from "../Utility/helpers/functions";
//var createBrowserHistory = require("history").createBrowserHistory;


const OktaSignInWidget = ({ config, onSuccess, onError, oktaConfig1_1 }) => {
    const [cookies, setCookie] = useCookies(['PWD_RESET']);
    const history = useHistory()
    const widgetRef = useRef();
    const sourceUrl = decodeURIComponent(window.location.href);
    const sources = ["orderexpress", "pmod", "specialtyonline", "reportcenter", "oeintfc"];
    const [releaseFeatureFlag, setReleaseFeatureFlag] = useState(null);

    var fromUri = "";
    if (sourceUrl.includes("TARGET=-SM-")) {
        fromUri = sourceUrl.substring(sourceUrl.indexOf("TARGET=-SM-") + 11);
    } else if (sourceUrl.includes("fromURI="))
        fromUri = sourceUrl.substring(sourceUrl.indexOf("fromURI=") + 8);

    const params = new URL(sourceUrl).searchParams;
    const login_hint = params.get('login_hint');
    if (login_hint) {
        config.username = login_hint; // Populates the SIW with the login  
    }

    var whereItCameFrom = "OERIAcute";
    if (fromUri.includes("specialtyonline")) {
        sessionStorage.removeItem("whereItCameFrom", "vantus")
        whereItCameFrom = "SPD";
    } else if (fromUri.includes("www1")) {
        sessionStorage.removeItem("whereItCameFrom", "vantus")
        whereItCameFrom = "RCNational";
    } else if (fromUri.includes("jarvis"))  {
        sessionStorage.removeItem("whereItCameFrom", "orderexpress")
        sessionStorage.setItem("whereItCameFrom", "vantus")
        whereItCameFrom = "vantus"
    }
    else if (fromUri.includes("vantus"))  {
            sessionStorage.setItem("whereItCameFrom", "vantus")
            whereItCameFrom = "vantus"
    }else if (sources.some(source => sourceUrl.includes(source)))  {
        sessionStorage.removeItem("whereItCameFrom", "vantus")
        sessionStorage.setItem("whereItCameFrom", "orderexpress")
    }else if(sourceUrl.endsWith('/forgot-password')){
       const isVantusPage = sessionStorage.getItem("whereItCameFrom") === "vantus"
       isVantusPage && sessionStorage.setItem("whereItCameFrom", "vantus")
    }
    else {
        if(!sourceUrl.includes("vantus")){
        sessionStorage.setItem("whereItCameFrom", "orderexpress")
        whereItCameFrom = "orderexpress"
        }
    }
    console.log("Config Value from Ngnix" + envConfig.FIRST_VAR + ' whereItCameFrom ' + whereItCameFrom);
    let headingCommonForRevoveryChallenge = "Password reset";
    let sessionTitle = sessionStorage.getItem("whereItCameFrom");
    useEffect(() => {
        if (!widgetRef.current) return false;
        const configOption = releaseFeatureFlag ? oktaConfig1_1 : config;
        const widget = new OktaSignIn(configOption);
        try {
            // after successful authentication with IdP
            widget.authClient.session.exists().then(function (sessionExists) {
                if (sessionExists) {
                    console.log("session exists");
                    widget.authClient.token
                        .getWithoutPrompt()
                        .then(function (response) {
                            widget.authClient.tokenManager.setTokens(response.tokens);
                            onSuccess(response.tokens);
                        }).catch(function (err) {
                            console.log("session exists but error");
                            console.error(err);
                            // handle OAuthError or AuthSdkError (AuthSdkError will be thrown if app is in OAuthCallback state)
                            widget.authClient
                                .closeSession()
                                .then(() => {
                                    window.location.reload();
                                })
                                .catch(onError);
                        });
                } else {
                    console.log("session does not exist");
                    widget.showSignInToGetTokens({ el: widgetRef.current, }).then(onSuccess).catch(onError);
                }
            });
        } catch (err) {
            console.log("unknown error");
            console.error(err);
            window.location.reload();
        }

        widget.on("afterError", function (context, error) {
            if (
                context.controller !== "primary-auth" &&
                context.controller !== "forgot-password" &&
                context.controller !== "recovery-challenge" &&
                context.controller !== "password-reset" &&
                context.controller !== "password-expired"
            ) {
                return;
            }
            var infoBoxError = document.getElementsByClassName("okta-form-infobox-error infobox infobox-error");
           
            if (context.controller === "primary-auth") {
                if (infoBoxError[0] && infoBoxError[0].innerText.includes("The resource owner or authorization server denied the request")) {
                    if(whereItCameFrom === "jarvis" || whereItCameFrom === "vantus"){
                        infoBoxError[0].innerHTML = '<span class="icon error-16"></span><div><span class="color-pink fw-bold">Sign in failed. </span>You are not authorized to access Vantus&#8482;HQ. For assistance, please contact the Enterprise IT Service Center at 1-800-326-6457.</div>';
                    }else{
                        infoBoxError[0].innerHTML = '<span class="icon error-16"></span><div><span class="color-pink fw-bold">Sign in failed. </span>You are not authorized to access Order Express. For assistance, please contact the Enterprise IT Service Center at 1-800-326-6457.</div>';
                    }
                }
                if (infoBoxError[0] && infoBoxError[0].innerText.includes("User is not assigned to the client application.")) {
                    if(whereItCameFrom === "jarvis" || whereItCameFrom === "vantus"){
                        infoBoxError[0].innerHTML = '<span class="icon error-16"></span><div><span class="color-pink fw-bold">Sign in failed. </span>You are not authorized to access Vantus&#8482;HQ. For assistance, please contact the Enterprise IT Service Center at 1-800-326-6457.</div>';
                    }else{
                        infoBoxError[0].innerHTML = '<span class="icon error-16"></span><div><span class="color-pink fw-bold">Sign in failed. </span>You are not authorized to access Order Express. For assistance, please contact the Enterprise IT Service Center at 1-800-326-6457.</div>';
                    }
                }
                
                if (infoBoxError[0] && infoBoxError[0].innerText.includes("Your account has been locked because you have exceeded the maximum number of login attempts")) {
                    infoBoxError[0].innerHTML = '<span class="icon error-16"></span><div><span class="color-pink fw-bold">Error. </span>Your account is locked. You have exceeded the maximum number of failed login attempts.<br> Wait 15 minutes and try again or unlock your account.<div class="link-error"><a class="mt-0" href=/signin/forgot-password>Unlock your account.</a></div></div>';
                }
                // if (infoBoxError[0] && infoBoxError[0].innerText.includes("Invalid username and/or password, please try again.")) {
                //     infoBoxError[0].innerHTML = '<span class="icon error-16"></span><div><span class="color-pink fw-bold">Your username or password is invalid.</span><br> Please re-enter your sign in information or choose one of the following options:<div class="link-error"><a href="/forgot-username" class="mr-2">Recover username</a> <a href="https://pdlogindev.cardinalhealth.com/signin/reset-password/">Reset password</a></div></div>';
                // }
                if (infoBoxError[0] && infoBoxError[0].innerText.includes("Invalid username and/or password, please try again.") || infoBoxError[0].innerText.includes(" ")|| (error.xhr.responseJSON.errorCode === "E0000004")) {
                    infoBoxError[0].innerHTML = `<span class="icon error-16"></span><div><span class="color-pink fw-bold">Your ${releaseFeatureFlag ? RELEASE_1_1.USER_ID : RELEASE_1_0.USERNAME_TEXT} or password is invalid.</span><br>Please re-enter your sign in information or select a help link at the bottom of the page.</div>`;
                }
            }
            if (context.controller === "recovery-challenge") {

                if (infoBoxError[0] && infoBoxError[0].innerText.includes("Your token doesn't match our records. Please try again.")) {
                    infoBoxError[0].innerHTML = '<span class="icon error-16"></span><div><span class="color-pink fw-bold">Error.</span> The passcode you entered is incorrect. Please try again or send a new passcode.</div>';
                }
            }
            if (context.controller === "password-reset") {
                if (infoBoxError[0] && infoBoxError[0].innerText.includes("Password requirements were not met")) {
                    infoBoxError[0].innerHTML = '<span class="icon error-16"></span><p>Password does not meet security requirements. Please try again.</p>';
                }
                if (infoBoxError[0] && infoBoxError[0].innerText.includes("You have accessed an account recovery link that has expired or been previously used")) {
                    infoBoxError[0].innerHTML = '<span class="icon error-16"></span><p>This activation link expired and can only be used once. New Users <a class="anchor-link" href=/signin/reactivate>click here</a> to activate your user or <a class="anchor-link" href=/signin/forgot-password>click here</a> to reset your password.</p>';
                }
                if (infoBoxError[0] && infoBoxError[0].innerText.includes("The resource owner or authorization server denied the request") ||  infoBoxError[0].innerHTML.match(/If you need further assistance, contact our service center at 1-800-ECOMHLP/)) {
                    infoBoxError[0].innerHTML = '<span class="icon error-16"></span><p>Your computer\'s IP address or login credential is not authorized to access Order Express. <br>If you need further assistance, contact our service center at 1-800-ECOMHLP (800-326-6457)</p>';
                    var updatePasswordBtn = document.getElementsByClassName('button button-primary')[0]
                    updatePasswordBtn.setAttribute('disabled', '');
                    updatePasswordBtn.classList.add('link-button-disabled');
                }
                // if (infoBoxError[0] && infoBoxError[0].innerText.includes("Your username or password is invalid.")){
                //     infoBoxError[0].innerHTML = '<span class="icon error-16"></span><p><span class="color-pink fw-bold">Error.</span> Password did not meet security requirements. Please try again.</p>';
                // }
            }

            if (context.controller === "password-expired") {
                if (infoBoxError[0] && infoBoxError[0].innerText.includes("Old Password is not correct")){
                    infoBoxError[0].innerHTML = '<span class="icon error-16"></span><p> Old password incorrect. Please try again.</p>';
                }
                if (infoBoxError[0] && infoBoxError[0].innerText.includes("The resource owner or authorization server denied the request") || 
                        infoBoxError[0].innerText.includes("Your password cannot be any of your last 9 passwords.")) {
                    infoBoxError[0].innerHTML = '<span class="icon error-16"></span><p><span class="color-pink fw-bold">Error.</span> Password does not meet security requirements. Please try again.</p>';
                }
            }
        });
        widget.on("afterRender", function (context) {
            if (
                context.controller !== "forgot-password" &&
                context.controller !== "recovery-challenge" &&
                context.controller !== "primary-auth" &&
                context.controller !== "forgot-username" &&
                context.controller !== "password-reset" &&
                context.controller !== "password-expired" &&
                context.controller !== "account-unlock" &&
                context.controller !== "account-unlocked" &&
                context.controller !== "recovery-loading"
            ) {
                return;
            }

            if (context.controller === "primary-auth") {
                if (document.getElementById("footer-container-div") !== null) {
                    document.getElementById('footer-container-div').className = 'footer-container-relative';
                   
                }
                if (document.getElementsByClassName('link js-help-link') !== null && document.getElementsByClassName('link js-help-link')[0] !== null) {
                    document.getElementsByClassName('link js-help-link')[0].setAttribute('target', '_self');
                }
                if (whereItCameFrom === 'RCNational') {
                    document.getElementsByClassName('link help js-help')[0].style.display = 'none';
                }
                document.getElementById('inner-pages-banner-image').classList.remove("inner-pages-banner-image");
                document.getElementById('login-wrapper').classList.remove("login-wrapper-inner-page");
                document.getElementById('logo-content').style.display = 'block';
                document.getElementById('widget-content').className = 'margin-left-widget';
                document.getElementById('okta-sign-in').style.height = '570px';
                if (document.getElementById("beacon-heading-login") == null){
                    var beaconHeading = document.createElement('div');
                    var titleSessionStorage = sessionStorage.getItem("whereItCameFrom");
                    beaconHeading.classList.add("beacon-heading-login");
                    beaconHeading.setAttribute("id","beacon-heading-login");
                    if(whereItCameFrom === "jarvis" || whereItCameFrom === "vantus" || titleSessionStorage === "vantus"){
                        document.querySelector('meta[name="description"]').content =
                            "More than pharmaceutical ordering, Vantus™ HQ is Cardinal Health’s digital hub for customers. Sign in to access product data, solution resources, detailed analytics and more.";
                        if (decodeURIComponent(window.location.href).includes("signin?fromURI")) {
                            document.querySelector("title").innerHTML = "Cardinal Health\u2122 Vantus\u2122 HQ | Sign in";
                        } else if(sourceUrl.endsWith('/redirect')){
                            document.querySelector("title").innerHTML = "Cardinal Health";
                        }
                        else {
                            document.querySelector("title").innerHTML = "Vantus\u2122 HQ";
                        }
                         beaconHeading.innerHTML = `<h1 className='h3 mb-1'>Welcome to Vantus\u2122 HQ!</h1> <h6 className='text-secondary mb-4_1'>Please enter your ${releaseFeatureFlag ? RELEASE_1_1.USER_ID : RELEASE_1_0.USERNAME_TEXT} and password.</h6>`
                         document.getElementsByClassName("auth-content")[0].prepend(beaconHeading);

                    }else{
                        if(sourceUrl.endsWith('/redirect')){
                            document.querySelector("title").innerHTML = "Cardinal Health";
                        }
                        else if(titleSessionStorage === "orderexpress"){
                            document.querySelector("title").innerHTML = "Order Express";
                        }
                         beaconHeading.innerHTML = `<h1 className='h3 mb-1'>Welcome to Order Express!</h1> <h6 className='text-secondary mb-4_1'>Please enter your ${releaseFeatureFlag ? RELEASE_1_1.USER_ID : RELEASE_1_0.USERNAME_TEXT} and password.</h6>`
                         document.getElementsByClassName("auth-content")[0].prepend(beaconHeading);
                    }
                   
                }
               
                // document.getElementById('okta-sign-in').classList.remove('no-beacond-heading-inner');
                if (document.getElementById("passcode-title-div") !== null) {
                    document.getElementById("passcode-title-div").remove();
                }
                if (document.getElementById("forgot-password-title-div") !== null)
                    document.getElementById("forgot-password-title-div").remove();
                var username_label = document.querySelector('label[for="okta-signin-username"]');
                if (username_label) username_label.textContent = releaseFeatureFlag ? RELEASE_1_1.USER_ID_LABEL : RELEASE_1_0.USERNAME_LABEL;
                var username_field = document.getElementById("okta-signin-username");
                var signin_btn =document.getElementById("okta-signin-submit");
                username_field.className = 'okta-sign-in-username';
                if (username_field) username_field.placeholder = releaseFeatureFlag ? RELEASE_1_1.ENTER_USER_ID : RELEASE_1_0.ENTER_USERNAME;
                var password_field = document.getElementById("okta-signin-password");
                if (password_field) password_field.placeholder = "Enter password";
                var helpLink = document.getElementsByClassName("js-help-link");
                if (helpLink && helpLink[0])
                    helpLink[0].textContent = "Sign in help";
                const existingSSOLink = document.querySelector('.js-sso-link');
                if (sessionStorage.getItem("whereItCameFrom") != "vantus" && !existingSSOLink) {
                // Adding SSO link to the auth-footer
                const authFooterContainer = document.querySelector('.auth-footer');
                const ssoLink = document.createElement("a");
                ssoLink.setAttribute('href', 'javascript:void(0);');
                ssoLink.className = 'link js-sso-link';
                ssoLink.innerHTML = `Single sign-on (SSO)`;
                authFooterContainer.appendChild(ssoLink);

                const ssoModal = document.querySelector('.sso-modal');
                const backgroundOverlay = document.querySelector('.background-overlay');

                ssoLink.addEventListener("click", function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    ssoModal.style.display = ssoModal.style.display === 'block' ? 'none' : 'block';
                    backgroundOverlay.style.display = backgroundOverlay.style.display === 'block' ? 'none' : 'block';
                });
                document.addEventListener("click", function (e) {
                    const ssoModal = document.querySelector('.sso-modal');
                    const backgroundOverlay = document.querySelector('.background-overlay');
                    if (!ssoModal.contains(e.target)) {
                    ssoModal.style.display = 'none';
                    backgroundOverlay.style.display = 'none';
                    }
                });
                }
                signin_btn.addEventListener('click', function() {
                    setTimeout(function() {
                        var infoBoxError = document.querySelectorAll('.okta-form-infobox-error.infobox.infobox-error')[0];
                        if (username_field.value === '' || password_field.value === '') {
                            if (infoBoxError) {
                                infoBoxError.innerHTML = `<span class="icon error-16"></span><div><span class="color-pink fw-bold">Your ${releaseFeatureFlag ? RELEASE_1_1.USER_ID : RELEASE_1_0.USERNAME_TEXT} or password is invalid.</span><br>Please re-enter your sign in information or select a help link at the bottom of the page.</div>`;
                            }
                        }
                    });
                });
            }

            if (context.controller === "forgot-password") {
              document.getElementById('okta-sign-in').classList.add('no-beacond-heading-inner');
                document.getElementById('okta-sign-in').style.removeProperty('height');
                headingCommonForRevoveryChallenge = "Password reset";

                var infoBoxError = document.getElementsByClassName("o-form-error-container");
                if (infoBoxError.length > 0) {
                    infoBoxError[0].style = "display: none";
                }

                if (document.getElementById("footer-container-div") !== null) {
                    document.getElementById('footer-container-div').className = 'footer-container-relative';
                }

                if (document.getElementById("passcode-title-div") !== null) {
                    document.getElementById("passcode-title-div").remove();
                }

                document.getElementById('logo-content').style.display = 'none';
                document.getElementById('inner-pages-banner-image').classList.add("inner-pages-banner-image");
                document.getElementById('login-wrapper').classList.add("login-wrapper-inner-page");
                document.getElementById('widget-content').className = 'margin-center-widget';
                if (document.getElementById("beacon-heading-login") !== null) {
                    document.getElementById("beacon-heading-login").remove();
                }
                

                var username_rec_field = document.getElementById("account-recovery-username");
                username_rec_field.placeholder = releaseFeatureFlag ? RELEASE_1_1.ENTER_USER_ID : RELEASE_1_0.ENTER_USERNAME;
                var containerDiv = document.getElementsByClassName("forgot-password");
                var username_rec_label = document.querySelector('label[for="account-recovery-username"]');
                if (username_rec_label) username_rec_label.textContent = releaseFeatureFlag ? RELEASE_1_1.USER_ID_LABEL : RELEASE_1_0.USERNAME_LABEL;
                if (document.getElementById("forgot-password-title-div") === null || !document.getElementById("forgot-password-title-div")) {
                    const fpTitleDiv = document.createElement("div");
                    fpTitleDiv.id = "forgot-password-title-div";
                    const fpTitleSpan = document.createElement("h1");
                    fpTitleSpan.id = "custom-title-txt";
                    const fpTitleText = document.createTextNode("Password reset");
                    fpTitleSpan.appendChild(fpTitleText);
                    fpTitleDiv.appendChild(fpTitleSpan);

                    const fpTitlePara = document.createElement("p");
                    fpTitlePara.id = "custom-title-info";
                    fpTitlePara.className = 'pb-2';
                    const fpTitleInfo = document.createTextNode(`Please enter your ${releaseFeatureFlag ? RELEASE_1_1.USER_ID : RELEASE_1_0.USERNAME_TEXT} and choose an account recovery method.`);
                    fpTitlePara.appendChild(fpTitleInfo);
                    fpTitleDiv.appendChild(fpTitlePara);

                    const fpTitleHrLine = document.createElement("hr");
                    fpTitleHrLine.id = "custom-title-hr";
                    fpTitleDiv.appendChild(fpTitleHrLine);

                    containerDiv[0].parentNode.insertBefore(fpTitleDiv, containerDiv[0]);
                }

                var pDiv = document.getElementsByClassName("mobile-recovery-hint");
                if (pDiv.length > 0) {
                    pDiv[0].innerText = "Text message and voice call recovery are only available if a phone number is authenticated for your account."
                    pDiv[0].classList.add("mobile-recovery-hint-custom");pDiv[0].classList.add("mb-4_2");pDiv[0].classList.add("mt-2");pDiv[0].classList.add("pb-0");
                }
                if (document.getElementById("custom-title-hr2") == null) {
                    const fpTitleHrLine2 = document.createElement("hr");
                    fpTitleHrLine2.id = "custom-title-hr2";
                    pDiv[0].appendChild(fpTitleHrLine2);
                }

                var emailButton = document.getElementsByClassName("email-button")[0];
                var unlockCallButtonNew = document.getElementsByClassName('call-button')[0];
                var unlockTextButtonNew = document.getElementsByClassName('sms-button')[0];
                var newEmailButton = emailButton.cloneNode(true);
                emailButton.parentNode.replaceChild(newEmailButton, emailButton);
                newEmailButton.addEventListener("click", async (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    //request API gateway for recovery token
                    const user = document.getElementById("account-recovery-username").value;
                    if (user === "") {
                        emailButton.click();
                        return;
                    }
                    const passwordRecoveryEmailResponse = await passwordRecoveryViaEmail(user,releaseFeatureFlag);
                    if (passwordRecoveryEmailResponse) {
                        history.push("/recovery/emailed");
                    } else {
                        history.push("/generic-error");
                    }

                });
                const commonFunction = () => {
                    const passCodeInputText = document.getElementById("account-recovery-username").value;
                    var inlineErrorMessageTwo = document.getElementsByClassName(`okta-form-input-error o-form-input-error o-form-explain`);
                    if(passCodeInputText == ""){
                        if(inlineErrorMessageTwo[0]){
                            inlineErrorMessageTwo[0].innerHTML = `<span class="icon icon-16 error-16-small" role="img" aria-label="Error"></span>${releaseFeatureFlag ? RELEASE_1_1.USER_ID_LABEL : RELEASE_1_0.USERNAME_LABEL} is required.`
                        }
                    }
                }
                newEmailButton.addEventListener("click", function (e) {
                    commonFunction();
                });
                unlockCallButtonNew.addEventListener("click", function (e) {
                    commonFunction();
                });
                unlockTextButtonNew.addEventListener("click", function (e) {
                    commonFunction();
                });
            }

            if (context.controller === "password-reset") {
                document.getElementById('okta-sign-in').classList.add('no-beacond-heading-inner');
                document.getElementById('okta-sign-in').style.removeProperty('height');
                if (document.getElementById("footer-container-div") !== null) {
                    document.getElementById('footer-container-div').className = 'footer-container-relative';
                }
                if (document.getElementById("passcode-title-div") !== null) {
                    document.getElementById("passcode-title-div").remove();
                }
                if (document.getElementById("forgot-password-title-div") !== null)
                    document.getElementById("forgot-password-title-div").remove();

                var subTitleDiv = document.getElementsByClassName("okta-form-subtitle o-form-explain");
                if (subTitleDiv.length > 0) {
                    subTitleDiv[0].remove();
                }
                document.getElementById('logo-content').style.display = 'none';
                document.getElementById('inner-pages-banner-image').classList.add("inner-pages-banner-image");
                document.getElementById('login-wrapper').classList.add("login-wrapper-inner-page");
                document.getElementById('widget-content').className = 'margin-center-widget';
                if (document.getElementById("beacon-heading-login") !== null) {
                    document.getElementById("beacon-heading-login").remove();
                }
                var inputTexts = document.getElementsByTagName("input");
                //q inputTexts[1].classList.add("mb-4_1")

                if (inputTexts && inputTexts[0].name.includes("newPassword")) 
                {
                    inputTexts[0].placeholder = "Enter password";
                }
                if (inputTexts && inputTexts[1].name.includes("confirmPassword")) 
                {
                    inputTexts[1].placeholder = "Enter password";
                }

                var inputLabels = document.getElementsByTagName("label");
                if (inputLabels && inputLabels[1].innerText.includes("Repeat password")) {
                    inputLabels[1].innerText = "Confirm password";
                }

                if (document.getElementsByClassName("password-reset").length > 0 && document.getElementById("password-reset-title-div") === null) {
                    var containerDiv = document.getElementsByClassName("okta-form-title o-form-head");
                    var containerDiv1 = document.getElementsByClassName("o-form-fieldset-container");
                    var passwordresetbuttondivInsert = document.getElementById("password-reset-button-div");
                    const passwordResetTitleDiv = document.createElement("div");
                    passwordResetTitleDiv.id = "password-reset-title-div";
                    const passwordResetTitleSpan = document.createElement("span");
                    passwordResetTitleSpan.id = "custom-title-txt";
                    const passwordResetTitleText = document.createTextNode("Password reset");
                    passwordResetTitleSpan.appendChild(passwordResetTitleText);
                    passwordResetTitleDiv.appendChild(passwordResetTitleSpan);

                    const passwordResetTitlePara = document.createElement("p");
                    passwordResetTitlePara.id = "custom-title-info";
                    const passwordResetTitleInfo = document.createTextNode("Please enter your new password.");
                    passwordResetTitlePara.appendChild(passwordResetTitleInfo);
                    passwordResetTitleDiv.appendChild(passwordResetTitlePara);

                    const passwordResetParaDiv = document.createElement("div");
                    passwordResetParaDiv.className = "password-reset-para-div";

                    const firstLine = document.createElement("p");
                    firstLine.id = "custom-para-p-medium";
                    const firstLineValue = document.createTextNode("Password requirements");
                    firstLine.appendChild(firstLineValue);
                    firstLine.className = "first-line"
                    passwordResetParaDiv.appendChild(firstLine);

                    const secondLine = document.createElement("p");
                    secondLine.id = "custom-para-p-1";
                    secondLine.classList.add('requirements-list');
                    const secondLineValue = document.createTextNode("Must be different than your previous 9 passwords");
                    secondLine.appendChild(secondLineValue);
                    passwordResetParaDiv.appendChild(secondLine);

                    const thirdLine = document.createElement("p");
                    thirdLine.id = "custom-para-p-2";
                    thirdLine.classList.add('requirements-list');
                    const thirdLineValue = document.createTextNode("A special character (Eg.\u00A0!@#$)");
                    thirdLine.appendChild(thirdLineValue);
                    passwordResetParaDiv.appendChild(thirdLine);

                    const forthLine = document.createElement("p");
                    forthLine.id = "custom-para-p-3";
                    forthLine.classList.add('requirements-list');
                    const forthLineValue = document.createTextNode(`No part of your ${releaseFeatureFlag ? RELEASE_1_1.USER_ID : RELEASE_1_0.USERNAME_TEXT}`);
                    forthLine.appendChild(forthLineValue);
                    passwordResetParaDiv.appendChild(forthLine);

                    const fifthLine = document.createElement("p");
                    fifthLine.id = "custom-para-p-4";
                    fifthLine.classList.add('requirements-list');
                    const fifthLineValue = document.createTextNode("At least 8 characters");
                    fifthLine.appendChild(fifthLineValue);
                    passwordResetParaDiv.appendChild(fifthLine);
                    
                    const sixthLine = document.createElement("p");
                    sixthLine.id = "custom-para-p-5";
                    sixthLine.classList.add('requirements-list');
                    const sixthLineValue = document.createTextNode("An uppercase letter");
                    sixthLine.appendChild(sixthLineValue);
                    passwordResetParaDiv.appendChild(sixthLine);

                    const seventhLine = document.createElement("p");
                    seventhLine.id = "custom-para-p-6";
                    seventhLine.classList.add('requirements-list');
                    const seventhLineValue = document.createTextNode("A lowercase letter");
                    seventhLine.appendChild(seventhLineValue);
                    passwordResetParaDiv.appendChild(seventhLine);
                    
                    const eightLine = document.createElement("p");
                    eightLine.id = "custom-para-p-7";
                    eightLine.classList.add('requirements-list');
                    const eightLineValue = document.createTextNode("A numeral");
                    eightLine.appendChild(eightLineValue);
                    passwordResetParaDiv.appendChild(eightLine);

                    const passwordResetButtonDiv = document.createElement("div");
                    passwordResetButtonDiv.id = "password-reset-button-div";

                    const passwordResetTitleHrLine2 = document.createElement("hr");
                    passwordResetTitleHrLine2.id = "custom-title-hr";
                    passwordResetButtonDiv.appendChild(passwordResetTitleHrLine2);

                    containerDiv[0].parentNode.insertBefore(passwordResetTitleDiv, containerDiv[0]);
                    containerDiv1[0].parentNode.insertBefore(passwordResetParaDiv, passwordresetbuttondivInsert);
                    containerDiv1[0].parentNode.appendChild(passwordResetButtonDiv, containerDiv1[0]);


                    if (document.getElementsByClassName("okta-form-subtitle o-form-explain").length > 0) {
                        var passwordReqPara = document.getElementsByClassName("okta-form-subtitle o-form-explain");
                        if (passwordReqPara.length > 0 && passwordReqPara[0].innerText.includes("Password requirements")) {
                            passwordReqPara[0].remove();
                        }
                    }
                }
                var oFormButton = document.getElementsByClassName('o-form-button-bar')[0]
                oFormButton.classList.add('mt-4_3')
                var updatePasswordBtn = document.getElementsByClassName('button button-primary')[0]
                updatePasswordBtn.value = "Confirm password";
                updatePasswordBtn.addEventListener("click", function (e) {
                    resetPwd.hasTriggered = true;
                    setCookie('PWD_RESET', 'YES', { path: '/', domain: envConfig.COOKIE_DOMAIN, encode: v => v });
                });
                
                const pBtns=document.querySelectorAll(".button");
                if(pBtns.length===1 && (!pBtns[0].id))// only adds id to primary btn if only one button is avaialble and which doesn't have any id
                {
                    pBtns[0].id="custom-primary-id"
                }
                var authFooter = document.getElementsByClassName("auth-footer clearfix")[0];
                authFooter.classList.add("auth-footerNew");

                inputTexts[0].addEventListener("focusout", function (e) {
                    setTimeout(function () {
                    var inlineErrorMessage = document.getElementsByClassName(`okta-form-input-error o-form-input-error o-form-explain`);
                    if(inputTexts[0].value == ""){
                        if(inlineErrorMessage[0]){
                            inlineErrorMessage[0].innerHTML = `<span class="icon icon-16 error-16-small" role="img" aria-label="Error"></span>New password is required.`
                        }
                    }
                },100);
                });


                // inputTexts[1].addEventListener("focusout", function (e) {
                //     setTimeout(function () {
                //     var inlineErrorMessage = document.getElementsByClassName(`okta-form-input-error o-form-input-error o-form-explain`);
                //     if(inputTexts[1].value == ""){
                //         if(inlineErrorMessage[1]){
                //             inlineErrorMessage[1].innerHTML = `<span class="icon icon-16 error-16-small" role="img" aria-label="Error"></span>Confirm password is required.`
                //         }
                //     }
                // },250);
                // });

                inputTexts[1].addEventListener("focusout", function (e) {
                    setTimeout(function () {
                        var inlineErrorMessage = document.getElementsByClassName(`okta-form-input-error o-form-input-error o-form-explain`);
                        if(inputTexts[1].value == ""){
                            if (inputTexts[0].value && inlineErrorMessage[0]){
                                inlineErrorMessage[0].innerHTML = `<span class="icon icon-16 error-16-small" role="img" aria-label="Error"></span>Confirm password is required.`
                            } else if(inlineErrorMessage[1]) {
                                inlineErrorMessage[1].innerHTML = `<span class="icon icon-16 error-16-small" role="img" aria-label="Error"></span>Confirm password is required.`
                            }
                        }
                    },100);
                });


                updatePasswordBtn.addEventListener("click", function (e) {
                        setTimeout(function () {
                            var infoBoxErrorNew = document.getElementsByClassName("okta-form-infobox-error infobox infobox-error");
                            var inlineErrorMessage = document.getElementsByClassName(" okta-form-input-error o-form-input-error o-form-explain");
                            var infoBoxError = document.getElementsByClassName("o-form-error-container");
                            if(inputTexts[0].value == "" && inputTexts[1].value == ""){
                                if(infoBoxErrorNew[0]){
                                    infoBoxErrorNew[0].innerHTML = '<span class="icon error-16"></span><div><span class="color-pink fw-bold">Error.</span> Please review required fields.</div>';
                                }
                                if(inlineErrorMessage[0]){
                                    inlineErrorMessage[0].innerHTML = `<span class="icon icon-16 error-16-small" role="img" aria-label="Error"></span>New password is required.`
                                }
                                if(inlineErrorMessage[1]){
                                    inlineErrorMessage[1].innerHTML = `<span class="icon icon-16 error-16-small" role="img" aria-label="Error"></span>Confirm password is required.`
                                }
                                if (infoBoxError.length > 0) {
                                    infoBoxError[0].style = "display: none";
                                }
                            }

                            else if(inputTexts[0].value == "" || inputTexts[1].value == ""){
                                if(infoBoxErrorNew[0]){
                                    infoBoxErrorNew[0].innerHTML = '<span class="icon error-16"></span><div><span class="color-pink fw-bold">Error.</span> Please review required fields.</div>';
                                }
                                if (infoBoxError.length > 0) {
                                    infoBoxError[0].style = "display: none";
                                }
                                if (inputTexts[0].value == "" && inputTexts[1].value && inlineErrorMessage[1]) {
                                    inlineErrorMessage[1].innerHTML = `<span class="icon icon-16 error-16-small" role="img" aria-label="Error"></span>New passwords must match.`
                                }
                            }

                            else if(inputTexts[0].value !== inputTexts[1].value){
                                
                                if (inlineErrorMessage[0]) {
                                    inlineErrorMessage[0].innerHTML = `<span class="icon icon-16 error-16-small" role="img" aria-label="Error"></span>New passwords must match.`
                                }

                                if(infoBoxErrorNew[0]){
                                    infoBoxErrorNew[0].innerHTML = '<span class="icon error-16"></span><div><span class="color-pink fw-bold">Error.</span> Please review required fields.</div>';
                                }
                                if (infoBoxError.length > 0) {
                                    infoBoxError[0].style = "display: none";
                                }
                                
                            }

                            else{
                                if (infoBoxError.length > 0) {
                                    infoBoxError[0].style = "display: block";
                                }
                            }

                        }, 1);
                       
                    });

            }


            if (context.controller === "password-expired") {
                document.getElementById('okta-sign-in').classList.add('no-beacond-heading-inner');
                document.getElementById('okta-sign-in').style.removeProperty('height');

                if (document.getElementById("footer-container-div") !== null) {
                    document.getElementById('footer-container-div').className = 'footer-container-relative';
                }
                if (document.getElementById("passcode-title-div") !== null) {
                    document.getElementById("passcode-title-div").remove();
                }
                if (document.getElementById("forgot-password-title-div") !== null)
                    document.getElementById("forgot-password-title-div").remove();

                document.getElementById('logo-content').style.display = 'none';
                document.getElementById('inner-pages-banner-image').classList.add("inner-pages-banner-image");
                document.getElementById('login-wrapper').classList.add("login-wrapper-inner-page");
                document.getElementById('widget-content').className = 'margin-center-widget';
                if (document.getElementById("beacon-heading-login") !== null) {
                    document.getElementById("beacon-heading-login").remove();
                }

                if (document.getElementsByClassName("okta-form-divider") != null) {
                    document.getElementsByClassName("okta-form-divider")[0].style.display = 'none'
                }

                var inputTexts = document.getElementsByTagName("input");
                if (inputTexts && inputTexts[0].name.includes("oldPassword")) {
                    inputTexts[0].placeholder = "Enter old password";
                }
                if (inputTexts && inputTexts[1].name.includes("newPassword")) {
                    inputTexts[1].placeholder = "Enter new password";
                }
                if (inputTexts && inputTexts[2].name.includes("confirmPassword")) {
                    inputTexts[2].placeholder = "Enter confirm password";
                }

                var inputLabels = document.getElementsByTagName("label");
                if (inputLabels && inputLabels[2].innerText.includes("Repeat password")) {
                    inputLabels[2].innerText = "Confirm password";
                }

                let passwordExpiryTitleMsg = "Your password is expired";
                if (document.getElementsByClassName("password-expired").length > 0 && document.getElementById("custom-title-div") === null) {
                    var formTitle = document.getElementsByClassName('okta-form-title')[0].innerText;
                    let passwordExpiryTitleInfoMsg = "Please complete the fields below to update your password.";

                    if (formTitle.includes('days')) {
                        passwordExpiryTitleMsg = formTitle + ".";
                    }

                    if (formTitle === "Your password will expire later today") {
                        passwordExpiryTitleMsg = "Your password will expire today." ;
                    }

                    if (!(passwordExpiryTitleMsg === "Your password is expired")) {
                        passwordExpiryTitleInfoMsg = "Update your password to avoid being locked out of your account.";
                        // document.getElementsByClassName('okta-form-subtitle').remove()
                    }
                    
                    var containerDiv = document.getElementsByClassName("okta-form-title o-form-head");
                    var containerDiv1 = document.getElementsByClassName("o-form-fieldset-container");
                    var passwordExpiredbuttondivInsert = document.getElementById("custom-password-expired-btn-div");

                    const passwordExpiredTitleDiv = document.createElement("div");
                    passwordExpiredTitleDiv.id = "custom-title-div";
                    const passwordExpiredTitleSpan = document.createElement("span");
                    passwordExpiredTitleSpan.id = "custom-title-txt";
                    if (!(passwordExpiryTitleMsg === "Your password is expired")) {
                        passwordExpiredTitleSpan.classList.add("password-expiry-soon-title");
                    }
                    const passwordExpiredTitleText = document.createTextNode(passwordExpiryTitleMsg);
                    passwordExpiredTitleSpan.appendChild(passwordExpiredTitleText);
                    passwordExpiredTitleDiv.appendChild(passwordExpiredTitleSpan);

                    const passwordExpiredTitlePara = document.createElement("p");
                    passwordExpiredTitlePara.id = "custom-title-info";
                    const passwordExpiredTitleInfo = document.createTextNode(passwordExpiryTitleInfoMsg);
                    passwordExpiredTitlePara.appendChild(passwordExpiredTitleInfo);
                    passwordExpiredTitleDiv.appendChild(passwordExpiredTitlePara);

                    const passwordExpiredParaDiv = document.createElement("div");
                    passwordExpiredParaDiv.className = "password-expired-para-div";

                    const firstLine = document.createElement("p");
                    firstLine.id = "custom-para-p-medium";
                    const firstLineValue = document.createTextNode("Password requirements");
                    firstLine.appendChild(firstLineValue);
                    firstLine.className = "first-line"
                    passwordExpiredParaDiv.appendChild(firstLine);

                    const secondLine = document.createElement("p");
                    secondLine.id = "custom-para-p-8";
                    secondLine.classList.add('requirements-list');
                    const secondLineValue = document.createTextNode("Must be different than your previous 9 passwords");
                    secondLine.appendChild(secondLineValue);
                    passwordExpiredParaDiv.appendChild(secondLine);

                    const thirdLine = document.createElement("p");
                    thirdLine.id = "custom-para-p-9";
                    thirdLine.classList.add('requirements-list');
                    const thirdLineValue = document.createTextNode("A special character (Eg.\u00A0!@#$)");
                    thirdLine.appendChild(thirdLineValue);
                    passwordExpiredParaDiv.appendChild(thirdLine);

                    const forthLine = document.createElement("p");
                    forthLine.id = "custom-para-p-10";
                    forthLine.classList.add('requirements-list');
                    const forthLineValue = document.createTextNode(`No part of your ${releaseFeatureFlag ? RELEASE_1_1.USER_ID : RELEASE_1_0.USERNAME_TEXT}`);
                    forthLine.appendChild(forthLineValue);
                    passwordExpiredParaDiv.appendChild(forthLine);

                    const fifthLine = document.createElement("p");
                    fifthLine.id = "custom-para-p-11";
                    fifthLine.classList.add('requirements-list');
                    const fifthLineValue = document.createTextNode("At least 8 characters");
                    fifthLine.appendChild(fifthLineValue);
                    passwordExpiredParaDiv.appendChild(fifthLine);
                    
                    const sixthLine = document.createElement("p");
                    sixthLine.id = "custom-para-p-12";
                    sixthLine.classList.add('requirements-list');
                    const sixthLineValue = document.createTextNode("An uppercase letter");
                    sixthLine.appendChild(sixthLineValue);
                    passwordExpiredParaDiv.appendChild(sixthLine);

                    const seventhLine = document.createElement("p");
                    seventhLine.id = "custom-para-p-13";
                    seventhLine.classList.add('requirements-list');
                    const seventhLineValue = document.createTextNode("A lowercase letter");
                    seventhLine.appendChild(seventhLineValue);
                    passwordExpiredParaDiv.appendChild(seventhLine);
                    
                    const eightLine = document.createElement("p");
                    eightLine.id = "custom-para-p-14";
                    eightLine.classList.add('requirements-list');
                    const eightLineValue = document.createTextNode("A numeral");
                    eightLine.appendChild(eightLineValue);
                    passwordExpiredParaDiv.appendChild(eightLine);

                    containerDiv[0].parentNode.insertBefore(passwordExpiredTitleDiv, containerDiv[0]);
                    containerDiv1[0].parentNode.insertBefore(passwordExpiredParaDiv, passwordExpiredbuttondivInsert);

                    if (document.getElementsByClassName("okta-form-subtitle o-form-explain").length > 0) {
                        var passwordReqPara = document.getElementsByClassName("okta-form-subtitle o-form-explain");
                        if (passwordReqPara.length > 0 && (passwordReqPara[0].innerText.includes("Password requirements") || passwordReqPara[0].innerText.includes('When password expires'))) {
                            passwordReqPara[0].remove();
                        }
                    }
                }
                var oFormButtonContainer = document.getElementsByClassName('o-form-button-bar')[0];
                oFormButtonContainer.classList.add("mt-4_3")
                var updatePasswordBtn = document.getElementsByClassName('button button-primary')[0];
                updatePasswordBtn.value = "Update password";
                updatePasswordBtn.addEventListener("click", function (e) {
                    resetPwd.hasTriggered = true;
                    setCookie('PWD_RESET', 'YES', { path: '/', domain: envConfig.COOKIE_DOMAIN, encode: v => v });

                    setTimeout(function () {
                        var inlineErrorMessage = document.getElementsByClassName(" okta-form-input-error o-form-input-error o-form-explain");
                        var infoBoxError = document.getElementsByClassName("o-form-error-container");
                        
                        if (inputTexts[0].value == "" || inputTexts[1].value == ""|| inputTexts[2].value == "") {

                            if (inputTexts[0].value == "") {
                                if (inlineErrorMessage[0]) {
                                    inlineErrorMessage[0].innerHTML = `<span class="icon icon-16 error-16-small" role="img" aria-label="Error"></span>Old password is required.`
                                }
                            }
                            if (inputTexts[1].value == "") {
                                if (inputTexts[0].value && inlineErrorMessage[0]){
                                    inlineErrorMessage[0].innerHTML = `<span class="icon icon-16 error-16-small" role="img" aria-label="Error"></span>New password is required.`
                                } else if(inlineErrorMessage[1]) {
                                    inlineErrorMessage[1].innerHTML = `<span class="icon icon-16 error-16-small" role="img" aria-label="Error"></span>New password is required.`
                                }
                            }
                            if (inputTexts[2].value == "") {
                                if (inputTexts[0].value && inputTexts[1].value && inlineErrorMessage[0]) {
                                    inlineErrorMessage[0].innerHTML = `<span class="icon icon-16 error-16-small" role="img" aria-label="Error"></span>Confirm password is required.`
                                } else if (((inputTexts[0].value && !inputTexts[1].value) || (!inputTexts[0].value && inputTexts[0].value)) && inlineErrorMessage[1]) {
                                    inlineErrorMessage[1].innerHTML = `<span class="icon icon-16 error-16-small" role="img" aria-label="Error"></span>Confirm password is required.`
                                } else if (inlineErrorMessage[2]) {
                                    inlineErrorMessage[2].innerHTML = `<span class="icon icon-16 error-16-small" role="img" aria-label="Error"></span>Confirm password is required.`
                                }
                            }
                            if (infoBoxError.length > 0) {
                                infoBoxError[0].style = "display: none";
                            }
                            if (inputTexts[0].value == "" && inputTexts[1].value && inputTexts[2].value && inlineErrorMessage[1]) {
                                inlineErrorMessage[1].innerHTML = `<span class="icon icon-16 error-16-small" role="img" aria-label="Error"></span>New passwords must match.`
                            }
                        } else if (inputTexts[1].value !== inputTexts[2].value) {
                            if (infoBoxError.length > 0) {
                                infoBoxError[0].style = "display: none";
                            }
                            if (inlineErrorMessage[0]) {
                                inlineErrorMessage[0].innerHTML = `<span class="icon icon-16 error-16-small" role="img" aria-label="Error"></span>New passwords must match.`
                            }
                        } else {
                            if (infoBoxError.length > 0) {
                                infoBoxError[0].style = "display: block";
                            }
                        }
                    }, 1);
                });
                var authFooter = document.getElementsByClassName("auth-footer clearfix")[0];
                authFooter.classList.add("auth-footerNew");
                document.getElementsByClassName("link help goto js-signout")[0].innerHTML = "Sign out";
                if (!(passwordExpiryTitleMsg === "Your password is expired")) {
                    var updatePasswordLink = document.getElementsByClassName("link help js-skip")[0];
                    updatePasswordLink.classList.add("goto");
                    updatePasswordLink.style = "width: 100%";
                    updatePasswordLink.innerHTML = "Update password later";
                    var signOutLink = document.getElementsByClassName("link help goto js-signout")[0];
                    signOutLink.style = "margin-top: 5px; width: 100%";
                    signOutLink.innerHTML = "Sign out";
                }
                
                inputTexts[0].addEventListener("focusout", function (e) {
                    setTimeout(function () {
                        var inlineErrorMessage = document.getElementsByClassName(`okta-form-input-error o-form-input-error o-form-explain`);
                        if (inputTexts[0].value == "") {
                            if (inlineErrorMessage[0]) {
                                inlineErrorMessage[0].innerHTML = `<span class="icon icon-16 error-16-small" role="img" aria-label="Error"></span>Old password is required.`
                            }
                        }
                    },100);
                });
          
                inputTexts[1].addEventListener("focusout", function (e) {
                    setTimeout(function () {
                        var inlineErrorMessage = document.getElementsByClassName(`okta-form-input-error o-form-input-error o-form-explain`);
                        if (inputTexts[1].value == "") {
                            if (inputTexts[0].value && inlineErrorMessage[0]){
                                inlineErrorMessage[0].innerHTML = `<span class="icon icon-16 error-16-small" role="img" aria-label="Error"></span>New password is required.`
                            } else if(inlineErrorMessage[1]) {
                                inlineErrorMessage[1].innerHTML = `<span class="icon icon-16 error-16-small" role="img" aria-label="Error"></span>New password is required.`
                            }
                        }
                    },100);
                });


                inputTexts[2].addEventListener("focusout", function (e) {
                    setTimeout(function () {
                        var inlineErrorMessage = document.getElementsByClassName(`okta-form-input-error o-form-input-error o-form-explain`);
                        if (inputTexts[2].value == "") {
                            if (inputTexts[0].value && inputTexts[1].value && inlineErrorMessage[0]) {
                                inlineErrorMessage[0].innerHTML = `<span class="icon icon-16 error-16-small" role="img" aria-label="Error"></span>Confirm password is required.`
                            } else if (((inputTexts[0].value && !inputTexts[1].value) || (!inputTexts[0].value && inputTexts[0].value)) && inlineErrorMessage[1]) {
                                inlineErrorMessage[1].innerHTML = `<span class="icon icon-16 error-16-small" role="img" aria-label="Error"></span>Confirm password is required.`
                            } else if (inlineErrorMessage[2]) {
                                inlineErrorMessage[2].innerHTML = `<span class="icon icon-16 error-16-small" role="img" aria-label="Error"></span>Confirm password is required.`
                            }
                        }
                    },100);
                });
            }

            //Store the username entered on the screen where user selects the recovery method
            if (context.controller === "recovery-challenge") {
              document.getElementById('okta-sign-in').classList.add('no-beacond-heading-inner');
                document.getElementById('okta-sign-in').style.removeProperty('height');

                if (document.getElementById("footer-container-div") !== null) {
                    document.getElementById('footer-container-div').className = 'footer-container-relative';
                }
                if (document.getElementById("passcode-title-div") !== null) {
                    document.getElementById("passcode-title-div").remove();
                }
                if (document.getElementById("forgot-password-title-div") !== null)
                    document.getElementById("forgot-password-title-div").remove();

                    document.getElementById('logo-content').style.display = 'none';
                    document.getElementById('inner-pages-banner-image').classList.add("inner-pages-banner-image");
                    document.getElementById('login-wrapper').classList.add("login-wrapper-inner-page");
                document.getElementById('widget-content').className = 'margin-center-widget';
                if (document.getElementById("beacon-heading-login") !== null) {
                    document.getElementById("beacon-heading-login").remove();
                }
                if (document.getElementsByClassName('link goto').length > 0) {
                    document.getElementsByClassName('link goto')[0].classList.remove('goto')
                }

                var inlineErrorMessage = document.getElementsByClassName(" okta-form-input-error o-form-input-error o-form-explain");
                var passCodeInputText = document.getElementsByTagName("input");
                if (passCodeInputText && passCodeInputText[0].name === "passCode") {
                    passCodeInputText[0].placeholder = "Enter passcode";
                    passCodeInputText[0].addEventListener("keypress", (event) => {
                        const key = event.key
                        if (!(/[0-9]/.test(key)))
                        event.preventDefault();
                    });
                    }

                if (document.getElementsByClassName("recovery-challenge") &&
                    document.getElementById("passcode-title-div") === null
                ) {
                    //&& (document.getElementById('passcode-title-div') === null && !document.getElementById('passcode-title-div'))) {
                    var containerDiv = document.getElementsByClassName("recovery-challenge");
                    const passCodeTitleDiv = document.createElement("div");
                    passCodeTitleDiv.id = "passcode-title-div";
                    const passCodeTitleSpan = document.createElement("h1");
                    passCodeTitleSpan.id = "custom-title-txt";
                    var emailLink = document.getElementsByClassName("send-email-link")[0];
                    if ((emailLink.innerText.includes('Unlock via email')) 
                    ) {
                        headingCommonForRevoveryChallenge = "Unlock account";
                    }
                 
                    const passCodeTitleText = document.createTextNode(headingCommonForRevoveryChallenge);
                    passCodeTitleSpan.appendChild(passCodeTitleText);
                    passCodeTitleDiv.appendChild(passCodeTitleSpan);

                    const passCodeTitlePara = document.createElement("p");
                    passCodeTitlePara.id = "custom-title-info";
                    const passCodeTitleInfo = document.createTextNode("Enter the one-time verification passcode sent to the phone number associated with your account.");
                    passCodeTitlePara.appendChild(passCodeTitleInfo);
                    passCodeTitleDiv.appendChild(passCodeTitlePara);
                    /*
                              const passCodeTitleHrLine = document.createElement("hr");
                              passCodeTitleHrLine.id = "custom-title-hr";
                              passCodeTitleDiv.appendChild(passCodeTitleHrLine);
                    */
                    containerDiv[0].parentNode.insertBefore(passCodeTitleDiv, containerDiv[0]);
                    let counterForPasscode = 0;
                    var buttonConfirmPasscode = document.getElementsByClassName("button button-primary")[0];
                    buttonConfirmPasscode.addEventListener("click", function (e) {
                        setTimeout(function () {
                            var infoBoxErrorNew = document.getElementsByClassName("okta-form-infobox-error infobox infobox-error");
                            var infoBoxError = document.getElementsByClassName("o-form-error-container");
                            if(passCodeInputText && passCodeInputText[0].value == ""){
                                if(infoBoxErrorNew[0]){
                                    infoBoxErrorNew[0].innerHTML = '<span class="icon error-16"></span><div><span class="color-pink fw-bold">Error.</span> Please review required fields.</div>';
                                }
                                if (infoBoxError.length > 0) {
                                    infoBoxError[0].style = "display: none";
                                }
                            }
                            else{
                                if (infoBoxError.length > 0) {
                                    infoBoxError[0].style = "display: block";
                                }
                                counterForPasscode = counterForPasscode + 1;
                                 if(counterForPasscode > 9){
                                  history.push('/signout');
                                 }
                            }
                        }, 1);
                       

                        setTimeout(() => {
                            var infoBoxErrorNewNew = document.getElementsByClassName("okta-form-infobox-error infobox infobox-error");
                            if (infoBoxErrorNewNew[0] && infoBoxErrorNewNew[0].innerText.includes("Your account is locked. Please contact your administrator.")) {
                                history.push('/signout');

                            }
                        },1000)
                       
                    });
                   
                }

                const formContainer = document.getElementsByClassName("o-form-fieldset-container")
                if (formContainer.length > 0 && document.getElementById("custom-title-hr") === null) {
                    const passCodeTitleHrLine = document.createElement("hr");
                    passCodeTitleHrLine.id = "custom-title-hr";
                    formContainer[0].parentNode.insertBefore(passCodeTitleHrLine, formContainer[0])
                }

                const passCodeTitleDiv1 = document.createElement("div");
                passCodeTitleDiv1.id = "passcode-title-custom-div";
                const passCodeTitlePara1 = document.createElement("p");
                passCodeTitlePara1.id = "custom-title-info-left";
                passCodeTitlePara1.className = "mobile-recovery-hint";
                const passCodeTitleInfo1 = document.createTextNode(`If a valid ${releaseFeatureFlag ? RELEASE_1_1.USER_ID : RELEASE_1_0.USERNAME_TEXT} was entered, you will receive the one-time passcode within a few minutes.`);
                const passCodeTitleSpan = document.createElement("span");


                const passCodeTitleDiv2 = document.createElement("div");
                passCodeTitleDiv2.id = "passcode-title-div-pa";

                const forgotUsernameLink = document.createElement("a");
                forgotUsernameLink.id = 'forgot-username-retrieve-link'
                forgotUsernameLink.href = '/forgot-username';
                forgotUsernameLink.className = 'mx-2'
                forgotUsernameLink.innerHTML = `Recover ${releaseFeatureFlag ? RELEASE_1_1.USER_ID : RELEASE_1_0.USERNAME_TEXT}.`;


                const passCodeTitlePara11 = document.createElement("p");
                passCodeTitlePara11.id = "custom-title-info-left2";
                passCodeTitlePara11.className = "mobile-recovery-hint mb-5 pb-0";
                passCodeTitlePara11.innerHTML = `Forgot your ${releaseFeatureFlag ? RELEASE_1_1.USER_ID : RELEASE_1_0.USERNAME_TEXT}?  `
                passCodeTitlePara11.appendChild(forgotUsernameLink)
                passCodeTitlePara1.appendChild(passCodeTitleInfo1);
                passCodeTitleDiv1.appendChild(passCodeTitlePara1);
                passCodeTitleDiv2.appendChild(passCodeTitlePara11);
                const passCodeHrDiv = document.createElement("div");
                passCodeHrDiv.id = "passcode-title-hr-div";
                const passCodeTitleHrLine2 = document.createElement("hr");
                passCodeTitleHrLine2.id = "custom-title-hr2";
                passCodeHrDiv.appendChild(passCodeTitleHrLine2);
                passCodeHrDiv.appendChild(document.createElement("br"));
                var resendPasscodeButton = document.getElementsByClassName("button sms-request-button margin-top-30 link-button");
                    resendPasscodeButton[0].classList.add("button-secondary")
                    resendPasscodeButton[0].classList.add("margin-top-30-custom")
            
                // resendPasscodeButton[0].className ="button sms-request-button margin-top-30 link-button button-secondary";
                var verifyPassCodeDiv = document.getElementsByClassName("o-form-button-bar");
                if (document.getElementById('custom-title-hr2') === null) {
                    verifyPassCodeDiv[0].children[0].parentNode.insertBefore(passCodeHrDiv, verifyPassCodeDiv[0].children[0]);
                    verifyPassCodeDiv[0].children[0].parentNode.insertBefore(passCodeTitleDiv2, verifyPassCodeDiv[0].children[0]);
                    verifyPassCodeDiv[0].children[0].parentNode.insertBefore(passCodeTitleDiv1, verifyPassCodeDiv[0].children[0]);
                }
                verifyPassCodeDiv[0].appendChild(resendPasscodeButton[0]);

                //document.getElementById('custom-title-hr2').appendChild(document.createElement("br"))
                //overwrite the "send via email" soft link on the SMS or voice call recovery pages
                var emailLink = document.getElementsByClassName("send-email-link")[0];
                let softLinkResetViaEmail = "/signin/forgot-password"
                if (emailLink.innerText.includes('Unlock via email')) {
                    softLinkResetViaEmail = "/signin/unlock"
                }
                if (emailLink.innerText.includes(`Didn't receive a code? Reset via email`)) {
                    emailLink.innerText = "Choose a different recovery method"
                }
                if (emailLink.innerText.includes(`Didn't receive a code? Unlock via email`)) {
                    emailLink.innerText = "Choose a different recovery method"
                }
                emailLink.addEventListener("click", function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    history.push(softLinkResetViaEmail);
                });
                if (document.getElementById("forgot-username-retrieve-link-new") == null) {
                    const forgotUsernameLinkNew = document.createElement("a");
                    forgotUsernameLinkNew.id = 'forgot-username-retrieve-link-new'
                    forgotUsernameLinkNew.href = '/forgot-username';
                    forgotUsernameLinkNew.className = 'link forgot-username-retrieve-link-new'
                    forgotUsernameLinkNew.innerHTML = `Recover ${releaseFeatureFlag ? RELEASE_1_1.USER_ID : RELEASE_1_0.USERNAME_TEXT}`;
                    var authFooter = document.getElementsByClassName("auth-footerNew clearfix")[0];
                    authFooter.parentNode.insertBefore(forgotUsernameLinkNew,authFooter)
                }
            }

            if (context.controller === 'account-unlock') {
              document.getElementById('okta-sign-in').classList.add('no-beacond-heading-inner');
                document.getElementById('okta-sign-in').style.removeProperty('height');
                headingCommonForRevoveryChallenge = "Unlock account";
                var infoBoxError = document.getElementsByClassName("o-form-error-container");
                if (infoBoxError.length > 0) {
                    infoBoxError[0].style = "display: none";
                }
                if (document.getElementById("footer-container-div") !== null) {
                    document.getElementById('footer-container-div').className = 'footer-container-relative';
                }
                document.getElementById('logo-content').style.display = 'none';
                document.getElementById('inner-pages-banner-image').classList.add("inner-pages-banner-image");
                document.getElementById('login-wrapper').classList.add("login-wrapper-inner-page");
                document.getElementById('widget-content').className = 'margin-center-widget';
                if (document.getElementById("beacon-heading-login") !== null) {
                    document.getElementById("beacon-heading-login").remove();
                }
                var username_rec_field = document.getElementById("account-recovery-username");
                username_rec_field.placeholder = releaseFeatureFlag ? RELEASE_1_1.ENTER_USER_ID : RELEASE_1_0.ENTER_USERNAME;
                var username_rec_label = document.querySelector('label[for="account-recovery-username"]');
                if (username_rec_label) username_rec_label.textContent = releaseFeatureFlag ? RELEASE_1_1.USER_ID_LABEL : RELEASE_1_0.USERNAME_LABEL;
                var username_label_field = document.getElementsByClassName("o-form-label")[0];
                if (document.getElementById("account-unlock-title-div") === null || !document.getElementById("account-unlock-title-div")) {
                    const acTitleDiv = document.createElement("div");
                    acTitleDiv.id = "account-unlock-title-div";
                    const acTitleSpan = document.createElement("h1");
                    acTitleSpan.id = "custom-title-txt";
                    const acTitleText = document.createTextNode("Unlock account");
                    acTitleSpan.appendChild(acTitleText);
                    acTitleDiv.appendChild(acTitleSpan);

                    const acTitlePara = document.createElement("p");
                    acTitlePara.id = "custom-title-info";
                    acTitlePara.className = 'pb-2 mb-1';
                    const acTitleInfo = document.createTextNode(`Please enter your ${releaseFeatureFlag ? RELEASE_1_1.USER_ID : RELEASE_1_0.USERNAME_TEXT} and choose an account recovery method.`);
                    acTitlePara.appendChild(acTitleInfo);
                    acTitleDiv.appendChild(acTitlePara);

                    const acTitleHrLine = document.createElement("hr");
                    acTitleHrLine.id = "custom-title-hr";
                    acTitleDiv.appendChild(acTitleHrLine);

                    username_label_field.parentNode.insertBefore(acTitleDiv, username_label_field);
                    var mobile_para = document.getElementsByClassName('mobile-recovery-hint')
                    if (mobile_para.length > 0) {
                        mobile_para[0].innerText = mobile_para[0].innerText.concat(".");
                        mobile_para[0].classList.add("pb-4")
                    }
                    const acTitleHrLine2 = document.createElement("hr");
                    acTitleHrLine2.id = "custom-title-hr2";
                    mobile_para[0].appendChild(acTitleHrLine2);
                   
                    var unlockTextButton = document.getElementsByClassName('sms-button')[0];
                    if (unlockTextButton && unlockTextButton.innerText === 'Send SMS') {
                        unlockTextButton.innerText = releaseFeatureFlag ? RELEASE_1_1.SMS_BTN : RELEASE_1_0.SMS_BTN;
                    }
                    var unlockCallButton = document.getElementsByClassName('call-button')[0];
                    if (unlockCallButton && unlockCallButton.innerText === 'Voice Call') {
                        unlockCallButton.innerText = releaseFeatureFlag ? RELEASE_1_1.VOICE_CALL_BTN : RELEASE_1_0.VOICE_CALL_BTN;
                    }
                    var unlockEmailButton = document.getElementsByClassName('email-button')[0];
                    if (unlockEmailButton && unlockEmailButton.innerText === 'Send Email') {
                        unlockEmailButton.classList.add("margin-bottom-0");
                        unlockEmailButton.innerText = releaseFeatureFlag ? RELEASE_1_1.EMAIL_BTN : RELEASE_1_0.EMAIL_BTN;
                    }
                    const commonFunction = () => {
                        const passCodeInputText = document.getElementById("account-recovery-username").value;
                        var inlineErrorMessageTwo = document.getElementsByClassName(`okta-form-input-error o-form-input-error o-form-explain`);
                        if(passCodeInputText == ""){
                            if(inlineErrorMessageTwo[0]){
                                inlineErrorMessageTwo[0].innerHTML = `<span class="icon icon-16 error-16-small" role="img" aria-label="Error"></span>${releaseFeatureFlag ? RELEASE_1_1.USER_ID_LABEL : RELEASE_1_0.USERNAME_LABEL} is required.`
                            }
                        }
                    }
                    unlockTextButton.addEventListener("click", function (e) {
                        commonFunction();
                    });
                    unlockCallButton.addEventListener("click", function (e) {
                        commonFunction();
                    });
                    unlockEmailButton.addEventListener("click", function (e) {
                        commonFunction();
                    });
                }
                var emailButton = document.getElementsByClassName("email-button")[0];
                var newEmailButton = emailButton.cloneNode(true);
                emailButton.parentNode.replaceChild(newEmailButton, emailButton);
                newEmailButton.addEventListener("click", async (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    //request API gateway for recovery token
                    const user = document.getElementById("account-recovery-username").value;
                    if (user === "") {
                        emailButton.click();
                        return;
                    }
                    const isSuccess = await accountUnlockViaEmail(user,releaseFeatureFlag);
                    if (isSuccess) {
                        history.push("/unlock/emailed");
                    } else {
                        history.push("/generic-error");
                    }
                });
            }
            if (context.controller === 'account-unlocked') {
              document.getElementById('okta-sign-in').classList.add('no-beacond-heading-inner');
                document.getElementById('okta-sign-in').style.removeProperty('height');

                if (document.getElementById("footer-container-div") !== null) {
                    document.getElementById('footer-container-div').className = 'footer-container';
                }
                const userDetails = widget.getUser();
                if (userDetails.profile.login !== "") {
                    accountUnlockConfirmationEmail(userDetails.profile.login,releaseFeatureFlag)
                }
                document.getElementById('logo-content').style.display = 'none';
                document.getElementById('inner-pages-banner-image').classList.add("inner-pages-banner-image");
                document.getElementById('login-wrapper').classList.add("login-wrapper-inner-page");
                document.getElementById('widget-content').className = 'margin-center-widget';
                if (document.getElementById("beacon-heading-login") !== null) {
                    document.getElementById("beacon-heading-login").remove();
                }
                if (document.getElementById("passcode-title-div") !== null) {
                    document.getElementById("passcode-title-div").remove();
                }
                if (document.getElementById("forgot-password-title-div") !== null)
                    document.getElementById("forgot-password-title-div").remove();

                var para = document.getElementsByClassName('okta-form-subtitle')[0]; 
                para.innerHTML = "";
                if (document.getElementById("account-unlocked-title-div") === null) {
                    const acULTitleDiv = document.createElement("div");
                    acULTitleDiv.id = "account-unlocked-title-div";
                    const acULTitleH1 = document.createElement("h1");
                    acULTitleH1.id = "custom-title-txt";
                    const acULTitleText = document.createTextNode("Your account is unlocked");
                    acULTitleH1.appendChild(acULTitleText);
                    acULTitleDiv.appendChild(acULTitleH1);

                    const acUnlockedTitlePara = document.createElement("p");
                    acUnlockedTitlePara.id = "custom-title-info";
                    acUnlockedTitlePara.className = "p-0";
                    const acUnlockedTitleInfo = document.createTextNode(`You can now sign in using your existing ${releaseFeatureFlag ? RELEASE_1_1.USER_ID : RELEASE_1_0.USERNAME_TEXT} and password.`);
                    acUnlockedTitlePara.appendChild(acUnlockedTitleInfo);
                    acULTitleDiv.appendChild(acUnlockedTitlePara);

                    const acUnlockButtonDiv = document.createElement("div");
                    acUnlockButtonDiv.id = "unlocked-button-wrapper";
                    acUnlockButtonDiv.className = "mt-4";
                    const acSignInButton = document.getElementsByClassName('button button-primary button-wide link-button')[0];
                    acSignInButton.innerHTML = "Sign in";
                    acSignInButton.classList.add("margin-bottom-0");
                    acUnlockButtonDiv.appendChild(acSignInButton);
                    
                    const acUnlockLinksDiv = document.createElement("div");
                    acUnlockLinksDiv.className = "auth-footer"
                    const acUnlockLinksUL = document.createElement("ul");
                    acUnlockLinksUL.className = "help-links js-help-links";
                    
                    const acUnlockForgotUsernameLI = document.createElement("li");
                    const acUnlockForgotUsernameLink = document.createElement("a");
                    acUnlockForgotUsernameLink.className = 'link';
                    acUnlockForgotUsernameLink.href = '/forgot-username';
                    acUnlockForgotUsernameLink.innerHTML = releaseFeatureFlag ? RELEASE_1_1.FORGOT_USER_ID : RELEASE_1_0.FORGOT_USERNAME;
                    acUnlockForgotUsernameLI.appendChild(acUnlockForgotUsernameLink);

                    const acUnlockForgotPassLI = document.createElement("li");
                    const acUnlockForgotPassLink = document.createElement("a");
                    acUnlockForgotPassLink.className = 'link';
                    acUnlockForgotPassLink.href = '/signin/forgot-password';
                    acUnlockForgotPassLink.innerHTML = "Forgot password";
                    acUnlockForgotPassLI.appendChild(acUnlockForgotPassLink);

                    const acUnlockSignInHelpLI = document.createElement("li");
                    const acUnlockSignInHelpLink = document.createElement("a");
                    acUnlockSignInHelpLink.className = 'link';
                    acUnlockSignInHelpLink.href = config.helpLinks.help;
                    acUnlockSignInHelpLink.innerHTML = "Sign in help";
                    acUnlockSignInHelpLI.appendChild(acUnlockSignInHelpLink);

                    acUnlockLinksUL.appendChild(acUnlockForgotUsernameLI);
                    acUnlockLinksUL.appendChild(acUnlockForgotPassLI);
                    acUnlockLinksUL.appendChild(acUnlockSignInHelpLI);  
                    acUnlockLinksDiv.appendChild(acUnlockLinksUL);
                    acUnlockButtonDiv.appendChild(acUnlockLinksDiv);
                    acULTitleDiv.appendChild(acUnlockButtonDiv);

                    para.parentNode.insertBefore(acULTitleDiv, para);
                }
            }
            if (context.controller === 'recovery-loading') {
                document.getElementById('okta-sign-in').classList.add('no-beacond-heading-inner');
                document.getElementById('okta-sign-in').style.removeProperty('height');
                var infoBoxError = document.getElementsByClassName("o-form-error-container");
                if (infoBoxError.length > 0) {
                    infoBoxError[0].style = "display: none";
                }
                if (document.getElementById("footer-container-div") !== null) {
                    document.getElementById('footer-container-div').className = 'footer-container-relative';
                }
                document.getElementById('logo-content').style.display = 'none';
                document.getElementById('inner-pages-banner-image').classList.add("inner-pages-banner-image");
                document.getElementById('login-wrapper').classList.add("login-wrapper-inner-page");
                document.getElementById('widget-content').className = 'margin-center-widget';
                if (document.getElementById("beacon-heading-login") !== null) {
                    document.getElementById("beacon-heading-login").remove();
                }

                var recovery_loading_container = document.getElementsByClassName("o-form-content")[0];
                if (document.getElementById("recovery-loading-title-div") === null) {
                    const acRecoveryTitleDiv = document.createElement("div");
                    acRecoveryTitleDiv.id = "recovery-loading-title-div";
                    const acRecoveryTitleSpan = document.createElement("h1");
                    acRecoveryTitleSpan.id = "custom-title-txt";
                    const acRecoveryTitleText = document.createTextNode("Your activation link has expired");
                    acRecoveryTitleSpan.appendChild(acRecoveryTitleText);
                    acRecoveryTitleDiv.appendChild(acRecoveryTitleSpan);
                    
                    const acRecoveryTitlePara = document.createElement("p");
                    acRecoveryTitlePara.id = "custom-title-info";
                    acRecoveryTitlePara.className = "p-0";
                    const acRecoveryTitleInfo = document.createTextNode("Activation links can only be used once-this one is no longer active.");
                    acRecoveryTitlePara.appendChild(acRecoveryTitleInfo);
                    acRecoveryTitleDiv.appendChild(acRecoveryTitlePara);

                    const acRecoveryNewUsersPara = document.createElement("p");
                    acRecoveryNewUsersPara.id = "custom-title-new-users";
                    acRecoveryNewUsersPara.className = "mt-4 p-0";
                    const acRecoveryNewUsersSpan = document.createElement("span");
                    const acRecoveryNewUsersInfo = document.createTextNode("New users: ");
                    acRecoveryNewUsersSpan.className = "fw-bold";
                    const acRecoveryNewUserLink = document.createElement("a");
                    acRecoveryNewUserLink.className = 'link';
                    acRecoveryNewUserLink.href = '/reactivate';
                    acRecoveryNewUserLink.innerHTML = "request a new activation link.";
                    acRecoveryNewUsersSpan.appendChild(acRecoveryNewUsersInfo);
                    acRecoveryNewUsersPara.appendChild(acRecoveryNewUsersSpan);
                    acRecoveryNewUsersPara.appendChild(acRecoveryNewUserLink);

                    const acRecoveryExistingUsersPara = document.createElement("p");
                    acRecoveryExistingUsersPara.id = "custom-title-existing-users";
                    acRecoveryExistingUsersPara.className = "mt-4 p-0";
                    const acRecoveryExistingUsersSpan = document.createElement("span");
                    const acRecoveryExistingUsersInfo = document.createTextNode("Existing users: ");
                    acRecoveryExistingUsersSpan.className = "fw-bold";
                    const acRecoveryExistingUsersLink = document.createElement("a");
                    acRecoveryExistingUsersLink.className = 'link';
                    acRecoveryExistingUsersLink.href = '/signin/forgot-password';
                    acRecoveryExistingUsersLink.innerHTML = "reset your password.";
                    acRecoveryExistingUsersSpan.appendChild(acRecoveryExistingUsersInfo);
                    acRecoveryExistingUsersPara.appendChild(acRecoveryExistingUsersSpan);
                    acRecoveryExistingUsersPara.appendChild(acRecoveryExistingUsersLink);
                
                    acRecoveryTitleDiv.appendChild(acRecoveryNewUsersPara);
                    acRecoveryTitleDiv.appendChild(acRecoveryExistingUsersPara);

                    recovery_loading_container.parentNode.insertBefore(acRecoveryTitleDiv, recovery_loading_container);
                }
            }
        });
        return () => widget.remove();


    }, [config, onSuccess, onError, releaseFeatureFlag]);

    useEffect(() => {
        let whereItcameFrom_sessionStorage = sessionStorage.getItem("whereItCameFrom");
        if (whereItcameFrom_sessionStorage === "vantus") {
            // document.title = "Vantus\u2122 HQ";
        }
      },[]);
      useEffect(() => {
        const fetchFlag = async () => {
          const releaseFlag = await fetchFeatureFlag();
          setReleaseFeatureFlag(releaseFlag);
        };
      
        fetchFlag();
      }, []);

    return (
        <div id="main-page-div">
           {releaseFeatureFlag === null ? getSpiningLoader() :
            <div className="login-wrapper" id="login-wrapper">
                <div className="login-container">
                    <div className="">
                        <div className="row w-100 m-0">
                            <div className="col-xl-6 p-0 col-lg-12 left-box">
                                <div className="phx-form-login m-auto">
                                    <div id="widget-content" ref={widgetRef} />
                                    {sessionStorage.getItem("whereItCameFrom") != "vantus" && <Modal type="sso-modal" />}
                                    <div className="background-overlay"></div>
                                    <footer className="footer-login footer-login-home">
                                        <div id="footer-container-div" className="footer-container">
                                            <div id="footer">
                                                <a className="legal-privacy" target="_blank" href="https://www.cardinalhealth.com/us/en/Legal">Terms & conditions</a>
                                                &nbsp;|&nbsp;
                                                <a className="legal-privacy" target="_blank" href="http://cardinalhealth.com/us/en/PrivacyPolicy/PrivacyPolicy">Global privacy policy</a>
                                                &nbsp;|&nbsp;
                                                <a className="legal-privacy" target="_blank" href="https://www.cardinalhealth.com/en/support/cookie-policy.html">Cookie policy</a>
                                                {(sessionStorage.getItem("whereItCameFrom") ==="vantus") && <span>&nbsp;|&nbsp;<a className="legal-privacy" href={envConfig.EMPLOYEE_SSO_URL}>Employee sign in</a></span>}
                                            </div>
                                            <div className="copyright">
                                                <p className="mb-0 mt-1">© {new Date().getFullYear()} Cardinal Health. All rights reserved.</p>
                                            </div>

                                        </div>
                                    </footer>


                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-12 p-0  d-xl-block d-none" id="inner-pages-banner-image" >
                                <div  className="login-image-right" id="logo-content" style={{backgroundImage:`url(${banner})`}}>
                                    {(whereItCameFrom === "OERIAcute" || whereItCameFrom === "RCNational" || whereItCameFrom === "orderexpress") && (<div id="OE-logo">
                                        <figure id="login-image-banner" aria-labelledby="login-image-banner">
                                            <img src={banner} alt="login-image" className="w-100"></img>
                                        </figure>
                                        <div className="login-text-over-image">
                                            <p className="text-primary m-0">Order Express</p>
                                            <h3 className="m-0">Helping you improve people's lives.</h3>
                                        </div>
                                    </div>)}
                                    {whereItCameFrom === "SPD" && <div id="SPD-logo">
                                        {/* <figure id="login-image-banner" aria-labelledby="login-image-banner">
                                            <img src={banner} alt="login-image" className="w-100"></img>
                                        </figure> */}
                                        <div className="login-text-over-image">
                                            <p className="text-primary m-0">Specialty Online</p>
                                            <h3 className="m-0">Helping you improve people's lives.</h3>
                                        </div>
                                    </div>}


                                    {(whereItCameFrom === "jarvis" || whereItCameFrom === "vantus" || sessionTitle === "vantus") && (<div id="jarvis-logo">
                                        <figure id="login-image-banner" aria-labelledby="login-image-banner">
                                            <img src={banner} alt="login-image" className="w-100"></img>
                                        </figure>
                                        <div className="login-text-over-image">
                                            <p className="text-primary m-0">{'Cardinal Health\u2122 Vantus\u2122 HQ'}</p>
                                            <h3 className="m-0">Helping you improve people's lives.</h3>
                                        </div>
                                    </div>)}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-inner-pages-for-home d-none"><Footer /></div>
            </div>}
        </div>
    );
};

export default OktaSignInWidget;





